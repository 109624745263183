import { initializeApp } from "firebase/app";
import { getFirestore, getDoc, doc } from "firebase/firestore";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAsTPajm4F1KHq-tCO0dP9yLlQ1lkF6RkE",
  authDomain: "clicksource-849f8.firebaseapp.com",
  projectId: "clicksource-849f8",
  storageBucket: "clicksource-849f8.appspot.com",
  messagingSenderId: "160832061516",
  appId: "1:160832061516:web:1203899cd40f65f0c474e9",
  measurementId: "G-YZ954JX675"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

(function () {
  //write code to verify the user is actually paying and the domain is allowed to use the script
  if(getCookie("clicksourceUserValidated") && getCookie("clicksourceData") !== "") {
    runCode(JSON.parse(getCookie("clicksourceData")))
  } else {
    checkUser();
  }
  
})();

async function checkUser() {
  const hostname = window.location.hostname;
  const docRef = doc(db, "users", hostname);
  const docSnap = await getDoc(docRef);

  //see if doc exists and if it does, pass along it's data
  if (docSnap.exists()) {
    console.log("Document data:", docSnap.data());

    //run code only if user has an active subscription
    if(docSnap.data().activeSubscription) {

      //code to save session to not grab data from db anymore
      setCookie("clicksourceUserValidated", true, 1);
      setCookie("clicksourceData", JSON.stringify(docSnap.data()), 1);

      //code to verify if they have an active subscription and then pass along data
      runCode(docSnap.data());
    }
    //write code to verify if they have an active subscription and then pass along data
    runCode(docSnap.data());
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document found!");
  }
}

//function to set a cookie
function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

//function to grab a cookie
function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function hasQueryParams(url) {
  return url.includes('?');
}

function runCode(data) {
  //grab the domains from the database to attach parameters to
  //also grab all the parameters the client wants to pass or uses to the URL decoration

  //grab all the external links from the page
  const isExternalURL = (url) => new URL(url).origin !== location.origin;

  var domainsToDecorate = data.domains,
  queryParams = data.parameters;

  //loop through all links on the page to find external links
  for(var i = 0, l=document.links.length; i<l; i++) {

    //see link is external, check if it's in domain list to add UTM params to and only if it hasn't been added to the array already
    if(isExternalURL(document.links[i].href)) {
      if(!domainsToDecorate.includes(document.links[i].href)) {
        domainsToDecorate.push(document.links[i].href);
      }
    }
  }

  // do not edit anything below this line
  var links = document.querySelectorAll("a");


  //checks if URL doesn't have query params and if we have UTM's saved as a cookie, then append parameters to current URL
  if(!hasQueryParams(window.location.href)) {
    var collectedQueryParams = [];
    var queryString = "?";
;    for (var queryIndex = 0; queryIndex < queryParams.length; queryIndex++) {
        if (getCookie(queryParams[queryIndex]) !== "") {
          collectedQueryParams.push(
            queryParams[queryIndex] + "=" + getCookie(queryParams[queryIndex])
          );
        }
    }

    if(collectedQueryParams.length > 0) {
      for (var queryIndex = 0; queryIndex < collectedQueryParams.length; queryIndex++) {

        queryString = queryString + collectedQueryParams[queryIndex] + "&";

      }

      console.log(queryString)
      window.history.replaceState(null, null, queryString);
    }
  } 

  // check if links contain domain from the domainsToDecorate array and then decorates
  for (var linkIndex = 0; linkIndex < links.length; linkIndex++) {
    for (
      var domainIndex = 0;
      domainIndex < domainsToDecorate.length;
      domainIndex++
    ) {
      if (
        links[linkIndex].href.indexOf(domainsToDecorate[domainIndex]) > -1 &&
        links[linkIndex].href.indexOf("#") === -1
      ) {
        links[linkIndex].href = decorateUrl(links[linkIndex].href);
      }
    }
  }
  
  // decorates the URL with query params
  function decorateUrl(urlToDecorate) {
    urlToDecorate =
      urlToDecorate.indexOf("?") === -1
        ? urlToDecorate + "?"
        : urlToDecorate + "&";
    var collectedQueryParams = [];
    for (var queryIndex = 0; queryIndex < queryParams.length; queryIndex++) {
      if (getQueryParam(queryParams[queryIndex])) {
        collectedQueryParams.push(
          queryParams[queryIndex] + "=" + getQueryParam(queryParams[queryIndex])
        );
        setCookie(queryParams[queryIndex], getQueryParam(queryParams[queryIndex]),5);
      } else {
        if (getCookie(queryParams[queryIndex]) !== "") {
          collectedQueryParams.push(
            queryParams[queryIndex] + "=" + getCookie(queryParams[queryIndex])
          );
        }
      }
    }
    return urlToDecorate + collectedQueryParams.join("&");
  }

  // borrowed from https://stackoverflow.com/questions/831030/
  // a function that retrieves the value of a query parameter
  function getQueryParam(name) {
    if (
      (name = new RegExp("[?&]" + encodeURIComponent(name) + "=([^&]*)").exec(
        window.location.search
      ))
    )
      return decodeURIComponent(name[1]);
  }
}

